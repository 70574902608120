<template>
    <div class="w-full">
        <el-table :height="windowHeight - 200" :data="data">
            <el-table-column label-class-name="text-black text-left" prop="national_id" label="報到狀態" sortable width="120" fixed="left" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center space-x-2">
                        <vs-checkbox v-model="scope.row.is_checked" @click="updateChecked(scope.row.id, scope.row.is_checked)">報到</vs-checkbox>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="name" label="姓名" sortable width="120" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="national_id" label="身分證" sortable width="180" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.national_id }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="insurance_id" label="健保卡號" sortable width="180" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.insurance_id }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="sex" label="性別" sortable width="90" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ $common.getGender(scope.row.sex) }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="age" label="年齡" sortable width="90" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ $common.getAge(scope.row.birthday) }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="birthday" label="生日" sortable width="120" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.birthday }}</span>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column label-class-name="text-black text-left" prop="cta" label="動作" sortable width="180" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center cursor-pointer text-primary hover:font-semibold" @click="openCancelConfirm(scope.row.id)">
                        <span>取消報名</span>
                    </div>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- <vs-popup :active.sync="cancelConfirm" title="取消報名" @close="closeCancelConfirm">
            <div class="mt-4 mb-8 flex place-content-center place-items-center">
                <div class="mr-4 w-12 h-12 flex justify-center items-center">
                    <feather-icon class="text-danger" icon="AlertTriangleIcon" svgClasses="h-12 w-12" />
                </div>
                <span class="text-xl font-semibold">是否要取消報名？</span>
            </div>
            <div class="flex justify-end items-center space-x-2">
                <vs-button class="rounded whitespace-no-wrap" type="flat" @click="closeCancelConfirm">關閉</vs-button>
                <vs-button class="rounded whitespace-no-wrap" color="danger" @click="cancel">確定</vs-button>
            </div>
        </vs-popup> -->
    </div>
</template>
<script>
import { cancelHpaEventRegistration, updateHpaEventChecked } from '@/api/user'
export default {
    props: {
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
        data: {
            type: Array,
            required: true,
            default: [],
        },
    },
    // data() {
    //     return {
    //         cancelConfirm: false,
    //         hpa_event_patient_id: 0,
    //     }
    // },
    computed: {
        windowHeight() {
            return window.innerHeight
        },
    },
    methods: {
        // openCancelConfirm(id) {
        //     this.hpa_event_patient_id = id
        //     this.cancelConfirm = true
        // },
        // closeCancelConfirm() {
        //     this.hpa_event_patient_id = 0
        //     this.cancelConfirm = false
        // },
        // async cancel() {
        //     try {
        //         const payload = { hpa_event_patient_id: this.hpa_event_patient_id }
        //         await cancelHpaEventRegistration(payload)
        //         // this.closeCancelConfirm()
        //         this.$bus.$emit('cancelHpaEventRegistration')
        //     } catch (error) {
        //         console.error('取消整篩活動失敗', error)
        //     }
        // },
        async updateChecked(id, is_checked) {
            try {
                const payload = { hpa_event_patient_id: id, is_checked: !is_checked }
                await updateHpaEventChecked(payload)
            } catch (error) {
                console.error('更新民眾報到狀態失敗', error)
            }
        },
    },
}
</script>
